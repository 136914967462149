import ReactOnRails from 'react-on-rails';
import { decamelizeKeys } from 'humps';

const client = async (endpoint, { body, ...customConfig } = {}) => {
  const headers = ReactOnRails.authenticityHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    const bodyDecamelized = await decamelizeKeys(body);
    config.body = JSON.stringify(bodyDecamelized);
  }

  let data;
  try {
    const response = await window.fetch(endpoint, config);

    // Manually handle empty responses.
    const contentLength = response.headers.get('Content-Length');
    if (response.status === 204 || contentLength === '0') {
      data = {};
    } else {
      data = await response.json();
    }

    // Parse redirect locations and return them as the result.
    const location = response.headers.get('location');
    if (location) {
      window.location.assign(location);
      return {};
    }
    // Render
    if (response.status.toString().startsWith('3') && data.location) {
      window.location.assign(data.location);
      return {};
    }

    if (response.ok) {
      return data;
    }

    return Promise.reject(data || { error: response.statusText });
  } catch (error) {
    throw new Error(error.message);
  }
};

client.get = (endpoint, customConfig = {}) => {
  return client(endpoint, { ...customConfig });
};

client.post = (endpoint, body = null, customConfig = {}) => {
  return client(endpoint, { ...customConfig, body });
};

client.patch = (endpoint, body = null, customConfig = {}) => {
  return client(endpoint, { ...customConfig, method: 'PATCH', body });
};

client.delete = (endpoint, body = null, customConfig = {}) => {
  return client(endpoint, { ...customConfig, method: 'DELETE', body });
};

export default client;
